import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import { RxHamburgerMenu } from 'react-icons/rx'
import clsx from 'clsx'
import { TfiClose } from 'react-icons/tfi'

export default function Header(props) {
  const { scrolledPosition } = props
  const [isNavVisible, setNavVisibility] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [isActiveNav, setIsActiveNav] = useState('')

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 700px)')
    mediaQuery.addListener(handleMediaQueryChange)
    handleMediaQueryChange(mediaQuery)

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange)
    }
  }, [])

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }
  }

  const toggleNav = () => {
    setNavVisibility(!isNavVisible)
  }

  return (
    <header
      className={clsx(
        'Header',
        scrolledPosition > 300
          ? 'Header-scrolled'
          : isNavVisible
          ? 'navOpened'
          : '',
      )}
    >
      <div className="Logo">
        <a
          href="#home"
          onClick={() => {
            setNavVisibility(false)
            setIsActiveNav('about-us')
          }}
        >
          <img
            src={
              scrolledPosition > 300 || isNavVisible
                ? require('../assets/images/logo-black.png')
                : require('../assets/images/logo.png')
            }
            alt="logo"
            className="img1"
            style={
              scrolledPosition > 300 || isNavVisible
                ? { height: '9rem' }
                : { height: '5rem' }
            }
          />
        </a>
      </div>
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
        transitionName="example"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        <nav
          className={clsx('Nav', scrolledPosition > 300 ? 'scrolled-a' : '')}
        >
          <a
            href="#about-us"
            className={clsx(isActiveNav === 'about-us' ? 'activeNav' : '')}
            onClick={() => {
              setNavVisibility(false)
              setIsActiveNav('about-us')
            }}
          >
            About Us
          </a>
          <a
            href="#services"
            className={clsx(isActiveNav === 'services' ? 'activeNav' : '')}
            onClick={() => {
              setNavVisibility(false)
              setIsActiveNav('services')
            }}
          >
            Services
          </a>
          <a
            href="#gallery"
            className={clsx(isActiveNav === 'gallery' ? 'activeNav' : '')}
            onClick={() => {
              setNavVisibility(false)
              setIsActiveNav('gallery')
            }}
          >
            Gallery
          </a>
          <a
            href="#testimonials"
            className={clsx(isActiveNav === 'testimonials' ? 'activeNav' : '')}
            onClick={() => {
              setNavVisibility(false)
              setIsActiveNav('testimonials')
            }}
          >
            Testimonials
          </a>
        </nav>
      </CSSTransition>
      <button
        onClick={toggleNav}
        className="Burger"
        style={
          scrolledPosition > 300 || isNavVisible
            ? { color: 'var(--text-primary)' }
            : { color: 'var(--text-white)' }
        }
      >
        {isNavVisible ? <TfiClose /> : <RxHamburgerMenu />}
      </button>
    </header>
  )
}
