import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-flip'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

// import required modules
import { EffectFlip, Pagination, Navigation } from 'swiper'

export default function Testimonials() {
  return (
    <>
      <div className="text-test-cont">
        <span className="header">Testimonials</span>
      </div>
      <div className="testimonials-cont">
        <Swiper
          effect={'flip'}
          grabCursor={true}
          pagination={true}
          navigation={true}
          modules={[EffectFlip, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="testimonial-text-cont">
              <div className="header">Aditi Bhat</div>
              <div className="content">
                I always prefer them for transporting my goods as they are quick
                and reliable
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-text-cont">
              <div className="header">Riya Rao</div>
              <div className="content">
                I had hired them to transport some delicate goods and they did a
                gret job
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-text-cont">
              <div className="header">Yash Sharma</div>
              <div className="content">
                Best service till date. They delivered my good within the stated
                time
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <img
          src={require('../../assets/images/lorry.png')}
          alt=""
          className="lorry-image"
        />
      </div>
    </>
  )
}
