import React, { useRef } from 'react'
import Footer from '../../components/Footer'
import AboutUs from './AboutUs'
import Banner from './Banner'
import Gallery from './Gallery'
import Testimonials from './Testimonials'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Fab, Action } from 'react-tiny-fab'
import 'react-tiny-fab/dist/styles.css'
import { BsHeadset } from 'react-icons/bs'
import { AiOutlineMail } from 'react-icons/ai'
import { BsTelephoneFill } from 'react-icons/bs'
import Services from './Services'

const Home = () => {
  const contentRef = useRef()

  function handleBackClick() {
    contentRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  function handleClickPhone() {
    document.location.href = 'tel:9448971220'
  }

  function handleClickEmail() {
    window.location.href =
      'mailto:aklsdvg@gmail.com?subject=Subject&body=message%20goes%20here'
  }

  return (
    <div className="home-cont" id="home">
      <Banner handleBackClick={handleBackClick} />
      <div className="home-contents-cont" ref={contentRef}>
        <div id="about-us" className="routes-height" />
        <AboutUs />
        <div id="services" className="routes-height" />
        <div className="text-test-cont">
          <span className="header">Our Services</span>
        </div>
        <Services />
        <div id="gallery" className="routes-height" />
        <div className="text-test-cont">
          <span className="header">Gallery</span>
        </div>
        <Gallery />
        <div id="testimonials" className="routes-height" />
        <Testimonials />
      </div>
      <FloatingWhatsApp
        phoneNumber="9448971220"
        accountName="AK PACKERS AND MOVERS"
        avatar={require('../../assets/images/headphones.png')}
      />
      <Fab icon={<BsHeadset />} alwaysShowTitle={true}>
        <Action text="Email" onClick={() => handleClickEmail()}>
          <AiOutlineMail></AiOutlineMail>
        </Action>
        <Action text="Phone" onClick={() => handleClickPhone()}>
          <BsTelephoneFill color="#fff"></BsTelephoneFill>
        </Action>
      </Fab>
      <Footer />
    </div>
  )
}

export default Home
