import React from 'react'

const Footer = () => {
  return (
    <div className="footer-cont">
      <div className="first-cont">
        <div className="copyright-cont">
          <span className="copyright">
            © {new Date().getFullYear()} by A.K Transports. All Rights Reserved.
          </span>
          <span className="cookies">
            N.M.E. Building, Onion Market, P.B. Road, Davanagere - 577001.
          </span>
        </div>

        <div className="link-cont">
          <span className="header">Useful Links</span>
          <a href="#about-us" className="link">
            About Us
          </a>
          <a href="#services" className="link">
            Services
          </a>
          <a href="#gallery" className="link">
            Gallery
          </a>

          <a href="#testimonials" className="link">
            Testimonials
          </a>
        </div>
        <div className="contact-us">
          <div className="designed-by">
            Design made by{' '}
            <span
              className="items-highlighter"
              onClick={() => window.open('https://hummdesigns.com/')}
            >
              HummDesigns
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
