import React from 'react'
import PhotoAlbum from 'react-photo-album'
import useMobileDetect from 'use-mobile-detect-hook'

const Gallery = () => {
  const detectMobile = useMobileDetect()
  const isMobile = detectMobile.isMobile()
  const photos = [
    {
      src: require('../../assets/images/Gal1.jpeg'),
      width: 800,
      height: 600,
    },
    {
      src: require('../../assets/images/Gal13.jpeg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal14.jpeg'),
      width: 800,
      height: 600,
    },

    {
      src: require('../../assets/images/Gal4.jpeg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal5.jpeg'),
      width: 800,
      height: 600,
    },
    {
      src: require('../../assets/images/Gal6.jpeg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal7.jpeg'),
      width: 800,
      height: 600,
    },
    {
      src: require('../../assets/images/Gal8.jpeg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },

    {
      src: require('../../assets/images/Gal10.jpeg'),
      width: 800,
      height: 600,
    },
    {
      src: require('../../assets/images/Gal11.jpeg'),
      width: 800,
      height: 600,
    },
    {
      src: require('../../assets/images/Gal12.jpeg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal2.jpeg'),
      width: 800,
      height: 600,
    },

    {
      src: require('../../assets/images/Gal3.jpeg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal15.jpeg'),
      width: 800,
      height: 600,
    },
    {
      src: require('../../assets/images/Gal16.jpeg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal17.jpeg'),
      width: 800,
      height: 600,
    },
    {
      src: require('../../assets/images/Gal18.jpeg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal19.jpeg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal20.jpeg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },

    {
      src: require('../../assets/images/Gal21.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal22.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal23.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal24.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal25.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal26.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal27.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal28.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal29.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal30.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal31.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal32.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal33.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal34.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal35.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal36.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal37.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal38.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
    {
      src: require('../../assets/images/Gal39.jpg'),
      width: isMobile ? 2800 : 1600,
      height: isMobile ? 2800 : 900,
    },
  ]

  return (
    <div className="gallery-cont" id="gallery">
      <PhotoAlbum layout="columns" photos={photos} />
    </div>
  )
}

export default Gallery
