import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { routes } from './Routes'

const Navigations = () => {
  return (
    <Routes>
      {routes.map((route, key) => (
        <Route key={key} path={route.path} element={<route.element />} />
      ))}
    </Routes>
  )
}

export default Navigations
