import React from 'react'
import Slide from 'react-reveal/Slide'
import { BsHouseDoor, BsBuilding } from 'react-icons/bs'
import { BiBuildingHouse } from 'react-icons/bi'
const Services = () => {
  return (
    <div className="services-cont" id="services">
      <div className="container-main">
        <div className="items-cont">
          <Slide right>
            <div className="items-cont">
              <div className="content-with-icons">
                <div className="text-cont">
                  <BsHouseDoor className="icon"></BsHouseDoor>
                  <span className="header">Local Shifting</span>
                  <span className="content">
                    At AK Packers and Movers, we understand the challenges of
                    local shifting and strive to make it a hassle-free
                    experience for you. Our team of experienced professionals
                    will handle all aspects of your move, from packing to
                    transportation, ensuring that your belongings are handled
                    with care. At AK Packers and Movers, we believe in
                    transparency and honesty, which is why we provide upfront
                    pricing with no hidden fees. Our goal is to make your local
                    shifting experience as stress-free and affordable as
                    possible.
                  </span>
                </div>
              </div>
              <div className="content-with-icons">
                <div className="text-cont">
                  <BsBuilding className="icon"></BsBuilding>
                  <span className="header">Commercial Relocation</span>
                  <span className="content">
                    At AK Packers and Movers, we understand that commercial
                    relocation can be a complex and time-sensitive process. Our
                    team of experts will work with you to develop a tailored
                    plan that meets the unique needs of your business. Our
                    commercial relocation services include everything from
                    planning and preparation to packing, transportation, and
                    setup at your new location. We understand the importance of
                    minimizing downtime and disruptions during the move, which
                    is why we work quickly and efficiently to ensure that your
                    business is up and running as soon as possible.
                  </span>
                </div>
              </div>
              <div className="content-with-icons content-with-icons-background">
                <div className="text-cont">
                  <BiBuildingHouse className="icon icon-2"></BiBuildingHouse>
                  <span className="header">Corporate Relocation</span>
                  <span className="content">
                    At AK Packers and Movers, we understand that corporate
                    relocation can be a complex and challenging process. Our
                    team of experts is here to help make the transition as
                    seamless as possible. We understand the unique needs of
                    corporate clients, which is why we offer a wide range of
                    customizable services, including pre-move consultations,
                    on-site surveys, and custom packing and crating options. Our
                    experienced movers will handle your assets with care,
                    ensuring that they are protected during transit.
                  </span>
                </div>
              </div>
              <div className="content-with-icons">
                <div className="text-cont">
                  <img
                    src={require('../../assets/images/lorryIcon.png')}
                    alt=""
                    className="icon"
                  />
                  <span className="header">Transport Services</span>
                  <span className="content">
                    At AK Packers and Movers, we offer a wide range of transport
                    services to meet the diverse needs of our clients. Our fleet
                    of well-maintained vehicles, including trucks and vans, is
                    equipped to handle any size move, ensuring that your goods
                    are transported safely and efficiently. Our transport
                    services include local and long-distance transportation, as
                    well as specialized services for delicate and high-value
                    items. Our experienced drivers are knowledgeable in
                    navigating the roads and highways, and they take the
                    necessary precautions to ensure that your goods are
                    protected during transit.
                  </span>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </div>
  )
}

export default Services
