import React from 'react'
import Fade from 'react-reveal/Fade'

const AboutUs = () => {
  return (
    <div className="container about-us-cont">
      <Fade left>
        <div className="text-cont">
          <span className="header">About Us</span>
          <span className="content">
            At AK Packers & Movers, we are dedicated to providing professional
            and reliable moving services. We have been in business for over a
            decade and have the experience and expertise to handle any type of
            move. From residential to commercial, local to long distance, we've
            got you covered.
          </span>
          <span className="content">
            Our team of skilled movers will take care of all aspects of your
            move, from packing and loading to transportation and unloading. We
            use the latest tools and technology to ensure that your belongings
            are handled with care and reach their destination safely.
          </span>
          <span className="content">
            We understand that every move is unique and that's why we offer
            customized moving plans to suit your specific needs. Our team is
            always available to answer any questions you may have and provide
            support throughout the moving process.
          </span>
          <span className="content">
            We are fully licensed and insured and strive to maintain the highest
            standards of professionalism and integrity. Our goal is to make your
            move as smooth and stress-free as possible
          </span>
        </div>
      </Fade>
    </div>
  )
}

export default AboutUs
